import {
  INITIAL_DATA_CLEAR,
  INITIAL_DATA_SET,
  INITIAL_DATA_ERROR,
  WIDGET_CREATED,
  WIDGET_UPDATED,
  WIDGET_DELETED,
  WIDGETS_UPDATED,
  DASHBOARD_CREATED,
  DASHBOARD_UPDATED,
  DASHBOARD_DELETED,
  DASHBOARD_ORDER,
} from '../actions/constants';

export function widgetsReplace(widgets, newWidget) {
  return [...widgets.filter((widget) => widget.id !== newWidget.id), newWidget];
}

export function widgetsRemove(widgets, widgetId) {
  return widgets.filter((widget) => String(widget.id) !== String(widgetId));
}

export default function dashboardsReducer(state, action) {
  if (typeof state === 'undefined') {
    return {};
  }

  if (action.type === INITIAL_DATA_SET) {
    const dashboardData = {};
    if (action.payload && action.payload.dashboardList && action.payload.dashboardList) {
      action.payload.dashboardList.forEach((dashboard) => {
        if (dashboard) {
          dashboardData[dashboard.id] = dashboard;
        }
      });
    } else {
      // TODO throw error
    }
    return { ...state, ...dashboardData };
  }

  if (action.type === INITIAL_DATA_CLEAR || action.type === INITIAL_DATA_ERROR) {
    return {};
  }

  if (action.type === DASHBOARD_CREATED) {
    return {
      ...state,
      [action.payload.data.id]: action.payload.data,
    };
  }

  // TODO: add new action.type for shared dashboards and handle it here

  if (action.type === DASHBOARD_UPDATED) {
    if (state[action.payload.data.id]) {
      // shared_dashboard: true;
      // shared_user_copy: false;
      // shared_user_edit: true;
      // shared_user_name: 'Alex';
      console.log('state[action.payload.data.id]: ', state[action.payload.data.id]);
      // TODO: prevent shared data to be overwritten, e.g. owner name, access rights
      // TODO: add copy button functionality

      return {
        ...state,
        [action.payload.data.id]: {
          ...action.payload.data,
          widgets: state[action.payload.data.id].widgets,
        },
      };
    }
  }

  if (action.type === DASHBOARD_DELETED) {
    if (state[action.payload.id]) {
      const newState = { ...state };
      delete newState[action.payload.id];
      return newState;
    }
  }

  if (action.type === DASHBOARD_ORDER) {
    const newState = {};
    if (action.payload.data && action.payload.data.dashboard_order) {
      // change order of dashboards in state
      action.payload.data.dashboard_order.forEach((dashboardID) => {
        if (state[dashboardID]) {
          newState[dashboardID] = state[dashboardID];
        }
      });

      return newState;
    }
    return newState;
  }

  if (action.type === WIDGET_CREATED) {
    if (state[action.payload.data.dashboard_id]) {
      const newState = { ...state };
      newState[action.payload.data.dashboard_id] = {
        ...newState[action.payload.data.dashboard_id],
        widgets: widgetsReplace(
          newState[action.payload.data.dashboard_id].widgets || [],
          action.payload.data
        ),
      };
      return newState;
    }
  }

  if (action.type === WIDGET_UPDATED) {
    if (state[action.payload.data.dashboard_id]) {
      const newState = { ...state };
      newState[action.payload.data.dashboard_id] = {
        ...newState[action.payload.data.dashboard_id],
        widgets: widgetsReplace(
          newState[action.payload.data.dashboard_id].widgets || [],
          action.payload.data
        ),
      };
      return newState;
    }
  }

  if (action.type === WIDGETS_UPDATED) {
    const newState = { ...state };
    action.payload.forEach((widget) => {
      newState[widget.dashboard_id] = {
        ...newState[widget.dashboard_id],
        widgets: widgetsReplace(newState[widget.dashboard_id].widgets || [], widget),
      };
    });
    return newState;
  }

  if (action.type === WIDGET_DELETED) {
    const newState = {};
    Object.keys(state).forEach((key) => {
      newState[key] = {
        ...state[key],
        widgets: widgetsRemove(state[key].widgets || [], action.payload.id),
      };
    });
    return newState;
  }

  return state;
}
