import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { websocketOnline, websocketOffline } from '../actions/websocket';
import { DOCUMENT_CREATED, ACTION_IGNORED } from '../actions/constants';
import { getJWT, isValidJWT } from '../utils/jwt';
import { logout } from '../api/auth';
import { authLogout } from '../actions/auth';

let websocket;

class ReactWebsocket extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ws: null,
      attempts: 2,
    };
    this.connectWebsocket = debounce(this.connectWebsocket.bind(this));
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.connectWebsocket();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    this.shouldReconnect = false;

    websocket = this.state.ws;
    if (websocket && websocket.readyState === 0) {
      websocket.onopen = () => {
        websocket.close();
      };
    }
    if (websocket && websocket.readyState === 1) {
      websocket.close();
    }
  }

  setupWebsocket() {
    if (!websocket) {
      return;
    }

    const token = getJWT();

    websocket.onopen = () => {
      this.logging('Websocket open');
      if (token) {
        if (!isValidJWT(token)) {
          // logout and toast
          toast.warn(
            this.props.formatMessage({
              id: 'SESSION_EXPIRED',
              defaultMessage: 'Your session has expired. Please login again',
            }),
            { autoClose: 10000, theme: 'colored' }
          );
          logout().then(this.props.authLogout());
        } else {
          websocket.send('Bearer ' + token);
          this.logging('Websocket connected');
          if (this.props.onOpen) {
            this.props.onOpen();
          }
        }
      }
    };

    websocket.onmessage = (evt) => {
      this.props.onMessage(evt.data, this.props.isRealtime);
    };

    this.shouldReconnect = this.props.reconnect;
    websocket.onclose = () => {
      this.logging('Websocket disconnected');
      if (this.props.onClose) {
        this.props.onClose();
      }
      if (this.shouldReconnect) {
        const time = this.generateInterval(this.state.attempts);
        setTimeout(() => this.connectWebsocket(), time);
      }
    };
  }

  connectWebsocket() {
    if (!this.isComponentMounted) {
      return;
    }

    websocket = new WebSocket(this.props.url);

    this.setState((prevState, props) => ({
      attempts: prevState.attempts + 1,
      ws: websocket,
    }));
    this.setupWebsocket();
  }

  generateInterval(k) {
    if (this.props.reconnectIntervalInMilliSeconds > 0) {
      return this.props.reconnectIntervalInMilliSeconds;
    }
    return Math.min(30, 2 ** k - 1) * 1000;
  }

  logging(logline) {
    if (this.props.debug === true) {
      console.log('websocket:', logline); // eslint-disable-line no-console
    }
  }

  render() {
    return null;
  }
}

ReactWebsocket.defaultProps = {
  debug: process.env.NODE_ENV === 'development',
  reconnect: true,
  reconnectIntervalInMilliSeconds: 0,
  onOpen: undefined,
  onClose: undefined,
};

ReactWebsocket.propTypes = {
  url: PropTypes.string.isRequired,
  onMessage: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  debug: PropTypes.bool,
  reconnect: PropTypes.bool,
  reconnectIntervalInMilliSeconds: PropTypes.number,
};

function WebsocketComponent(props) {
  let ws = `wss://${window.location.host}/ws`;
  if (process.env.NODE_ENV === 'development') {
    ws = 'wss://cloud.dapona.com/ws';
    // ws = 'wss://dev.dapona.cloud/ws';
    ws = 'ws://192.168.1.128:8100/ws';
    // ws = 'ws://10.6.0.4:8100/ws';
    // ws = 'ws://ssf.desma.de/ws';
  }

  // if (isRealtime(props.from, props.to, props.isRealtime) === true) {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   return null;
  // }
  return (
    <ReactWebsocket
      url={ws}
      onOpen={props.onOpen}
      onClose={props.onClose}
      onMessage={props.onMsg}
      authLogout={props.authLogout}
      isRealtime={props.isRealtime}
      formatMessage={props.intl.formatMessage}
      logout={props.logout}
    />
  );
}

export function sendMessage(message) {
  // send message via websocket
  if (websocket && websocket.readyState === 1 && message) {
    try {
      websocket.send(message);
      // eslint-disable-next-line no-empty
    } catch {}
  }
}

function onMessage(message, isRealtime) {
  const action = JSON.parse(message);
  if (action.type === DOCUMENT_CREATED && isRealtime !== true) {
    return {
      type: ACTION_IGNORED, // not defined action => nothing happens
    };
  }
  // if (action.type === LAST_SEEN_RECEIVED && isRealtime !== true) {
  //   console.log('here', message);
  //   return {
  //     type: ACTION_IGNORED, // not defined action => nothing happens
  //   };
  // }
  return action;
}

export default connect((state) => ({ isRealtime: state.search.isRealtime }), {
  onOpen: websocketOnline,
  onClose: websocketOffline,
  onMsg: onMessage,
  authLogout,
})(injectIntl(WebsocketComponent));
